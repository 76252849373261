<template>
  <div
    :is="isModal ? 'Modal' : 'div'"
    class="two-factor-email-participants"
    v-bind="isModal
    ? {
      name: 'two-factor-email-participants',
      active: active,
      title: $t('searchByEmail/participantsTitle', [email]),
      subtitle: $t('searchByEmail/participantsSubtitle'),
      isDefault: true,
    }
    : null"
    @afterClose="afterClose"
  >
    <header
      class="two-factor-email-participants__heading"
      v-if="!isModal"
    >
      <h3 class="two-factor-email-participants__title">
        {{ $t('searchByEmail/participantsTitle', [email]) }}
      </h3>
      <p class="two-factor-email-participants__subtitle">
        {{ $t('searchByEmail/participantsSubtitle') }}
      </p>
    </header>
    <div class="two-factor-email-participants__list">
      <button
        class="two-factor-email-participants__item"
        v-for="participant in participants"
        :key="participant"
        @click="selectParticipant(participant)"
      >
      <span
        class="two-factor-email-participants__item-text">
        {{ $t('searchByEmail/participant', [participant]) }}
      </span>
        <Icon
          class="two-factor-email-participants__item-icon"
          name="right"
          size="l"
        ></Icon>
      </button>
    </div>
  </div>
</template>


<script lang="js">
import Modal from '@/components/modal/modal.vue';

export default {
  name: 'TwoFactorEmailParticipants',
  components: {
    Modal,
  },
  props: {
    isModal: Boolean,
    active: Boolean,
    participants: Array,
    email: String,
  },
  methods: {
    afterClose() {
      this.$emit('afterClose');
    },
    selectParticipant(number) {
      this.$emit('selectParticipant', number);
    },
  },
}
</script>


<style lang="scss" scoped>
  @import "./two-factor-email-participants";
</style>
