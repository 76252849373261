<template>
  <div class="search-participants-email">
    <div class="search-participants-email__container">
      <div class="search-participants-email__header">
        <h1 class="search-participants-email__title">
          {{ eventName }}
        </h1>
      </div>

      <div class="search-participants-email__content">
        <TwoFactorEmailParticipants
          :isModal="false"
          :participants="participants"
          :email="email"
          @selectParticipant="selectParticipant"
        />
      </div>
    </div>
  </div>
</template>


<script lang="js">
import TwoFactorEmailParticipants from "@/components/two-factor-email-participants/two-factor-email-participants.vue";

export default {
  name: 'SearchParticipantsEmail',
  props: {
    eventName: String,
    participants: Array,
    email: String,
  },
  components: {
    TwoFactorEmailParticipants,
  },
  methods: {
    selectParticipant(number) {
      this.$emit('selectParticipant', number);
    },
  },
}
</script>


<style lang="scss" scoped>
  @import "./search-participants-email";
</style>