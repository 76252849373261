var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.isModal ? 'Modal' : 'div',_vm._b({tag:"div",staticClass:"two-factor-email-participants",on:{"afterClose":_vm.afterClose}},'div',_vm.isModal
  ? {
    name: 'two-factor-email-participants',
    active: _vm.active,
    title: _vm.$t('searchByEmail/participantsTitle', [_vm.email]),
    subtitle: _vm.$t('searchByEmail/participantsSubtitle'),
    isDefault: true,
  }
  : null,false),[(!_vm.isModal)?_c('header',{staticClass:"two-factor-email-participants__heading"},[_c('h3',{staticClass:"two-factor-email-participants__title"},[_vm._v(" "+_vm._s(_vm.$t('searchByEmail/participantsTitle', [_vm.email]))+" ")]),_c('p',{staticClass:"two-factor-email-participants__subtitle"},[_vm._v(" "+_vm._s(_vm.$t('searchByEmail/participantsSubtitle'))+" ")])]):_vm._e(),_c('div',{staticClass:"two-factor-email-participants__list"},_vm._l((_vm.participants),function(participant){return _c('button',{key:participant,staticClass:"two-factor-email-participants__item",on:{"click":function($event){return _vm.selectParticipant(participant)}}},[_c('span',{staticClass:"two-factor-email-participants__item-text"},[_vm._v(" "+_vm._s(_vm.$t('searchByEmail/participant', [participant]))+" ")]),_c('Icon',{staticClass:"two-factor-email-participants__item-icon",attrs:{"name":"right","size":"l"}})],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }